module.exports={
  "author": {
    "name": "Jeremy Stashewsky",
    "email": "jstashewsky@salesforce.com",
    "website": "https://github.com/stash"
  },
  "contributors": [
    {
      "name": "Alexander Savin",
      "website": "https://github.com/apsavin"
    },
    {
      "name": "Ian Livingstone",
      "website": "https://github.com/ianlivingstone"
    },
    {
      "name": "Ivan Nikulin",
      "website": "https://github.com/inikulin"
    },
    {
      "name": "Lalit Kapoor",
      "website": "https://github.com/lalitkapoor"
    },
    {
      "name": "Sam Thompson",
      "website": "https://github.com/sambthompson"
    },
    {
      "name": "Sebastian Mayr",
      "website": "https://github.com/Sebmaster"
    }
  ],
  "license": "BSD-3-Clause",
  "name": "tough-cookie",
  "description": "RFC6265 Cookies and Cookie Jar for node.js",
  "keywords": [
    "HTTP",
    "cookie",
    "cookies",
    "set-cookie",
    "cookiejar",
    "jar",
    "RFC6265",
    "RFC2965"
  ],
  "version": "2.3.4",
  "homepage": "https://github.com/salesforce/tough-cookie",
  "repository": {
    "type": "git",
    "url": "git://github.com/salesforce/tough-cookie.git"
  },
  "bugs": {
    "url": "https://github.com/salesforce/tough-cookie/issues"
  },
  "main": "./lib/cookie",
  "files": [
    "lib"
  ],
  "scripts": {
    "suffixup": "curl -o public_suffix_list.dat https://publicsuffix.org/list/public_suffix_list.dat && ./generate-pubsuffix.js",
    "test": "vows test/*_test.js"
  },
  "engines": {
    "node": ">=0.8"
  },
  "devDependencies": {
    "async": "^1.4.2",
    "string.prototype.repeat": "^0.2.0",
    "vows": "^0.8.1"
  },
  "dependencies": {
    "punycode": "^1.4.1"
  }
}
