import { Component } from 'react';
import Cookie from '~/js/modules/Cookie';

export default class SubscribePopup extends Component {
    
    constructor() {
        super();
    }

    componentDidMount = () => {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const $window = $(window);
        const hideSubscribePopup = Cookie.has('HIDE_SUBSCRIBE_POPUP') ? Cookie.get('HIDE_SUBSCRIBE_POPUP') : 'false';
        const $footer = $('footer');
        const $subscribePopupElement = $('#subscribe-popup');

        if(hideSubscribePopup === "true"){
            $('#subscribe-popup-box').remove();
        } else if($footer.length > 0) {
            var distanceTop = $footer.offset().top - $window.height();
            if ($window.scrollTop() > distanceTop) {
                $('#subscribe-popup-box').fadeIn();
                $subscribePopupElement.animate({ 'bottom': '26px' }, 400);
            } else {
                $subscribePopupElement.stop(true).animate({ 'bottom': '-600px' }, 400);
                $('#subscribe-popup-box').fadeOut();
            }
        } else if($window.scrollTop() + $window.height() > $(document).height() - 200) {
            $('#subscribe-popup-box').fadeIn();
            $subscribePopupElement.animate({ 'bottom': '26px' }, 400);
        } else {
            $subscribePopupElement.stop(true).animate({ 'bottom': '-600px' }, 400);
            $('#subscribe-popup-box').fadeOut();
        }
    }
    
    handleOnClick = () => {
        const subscribePopupElement = $('#subscribe-popup');

        this.setNewsletterCookie();
        subscribePopupElement.remove();
    }
    
    setNewsletterCookie = () => {
        //60sec*60mins*24hours = 86400 (1 day), 5*60*1000(5mins)
        var d = new Date();
        d.setTime(d.getTime() + 86400000); // in milliseconds
        document.cookie = 'HIDE_SUBSCRIBE_POPUP=true;path=/;expires='+d.toGMTString()+';';
    }
    
    render() {
        const { cdnURL } = window.sandals_app.page;
        if((window.location.href).indexOf('/sign-up/') === -1) {
            return (
                <form className='flipper' id='subscribe-popup-box'>
                    <a href='/en/sign-up/'>
                        <img src='//cdn.sandals.com/sandals/v12/images/home/Sandals_SignUp_PopUp_12Jun18.png' alt="Sandals Signup Popup"/>
                    </a>
                    <div className='close-btn close' onClick={this.handleOnClick}></div>
                </form>
            );
        } else {
            return (<div className='hide'></div>);
        }
    }
}
