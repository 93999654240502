import $ from 'jquery';
import '~/js/libs/jquery.lazy';
import '~/js/libs/bootstrap/index';
import '~/js/libs/jquery.scrollbar';
import '~/js/libs/jquery.waypoint';
import '~/js/libs/quickquote/index';
import SubscribePopup from '~/js/components/subscribePopup';
import '~/js/modules/header-search';

const resortMenu = document.getElementById('resort-menu');

const header = () => {
    // const QuickQuote = require('~/js/components/QuickQuote').default;
    const $document = $(document);
    const $window = $(window);
    const $body = $('body');
    const $html = $('html');
    const $header = $('.js-header');
    const $main = $('.js-main');
    const $mainGroupsMenuTop = $('.js-main-group-menu-top');
    const $menuBtn = $('.js-menu-btn');
    const $resortsBtn = $('.js-resorts-btn');
    const $headerBottom = $('.js-header-bottom');
    const $mobileResorts = $('.js-mobile-resorts');
    // const $scroll = $('.js-scroll');
    const $mnContentBtn = $('.js-mn-content-btn');
    const $mnNavbarList = $('.js-mn-navbar-list');
    const $customSelects = $('.js-cr-select');
    // const quickQuoteElms = document.querySelectorAll('.rct-qq-resort-menu');
    const $bookNowButton = $('.js-hm-book-now');
    const $bookNowOverlay = $('.js-hm-book-now-overlay');

    let isMenuOpen = false;
    let isResortsOpen = false;
    let scrollTop;
    
    // set cobrand navs
    const stickyNavs = ['resort-menu', 'nav'];
    let $currentNav = $();
    let stickyNavSelector = '';

    const hasCobrand = Object.keys(sandals_app.referral).length !== 0;
    const hasNav = () => $currentNav.length !== 0;

    // look for sticky nav
    const setCurrentNav = () => {
        if(hasCobrand) {
            stickyNavs.find(navSelector => {
                $currentNav = $('#' + navSelector);
                stickyNavSelector = navSelector;

                return hasNav();
            });
        }
    };

    // get top of elem
    const getTop = (elem) => {
        const box = elem.getBoundingClientRect();

        const body = document.body;
        const docEl = document.documentElement;

        const scrollTopNav = window.pageYOffset || docEl.scrollTop || body.scrollTop;
        const clientTop = docEl.clientTop || body.clientTop || 0;
        const top  = box.top +  scrollTopNav - clientTop;

        return Math.round(top);
    };

    // if has cobrand add paddings
    if (hasCobrand) {
        const body = document.getElementsByTagName('body')[0];
        const cobrandPaddingElement = document.createElement('div');

        // add padding to main page
        cobrandPaddingElement.classList.add('js-cobrand-padding');
        cobrandPaddingElement.style.height = $('.js-cobrand').height() + 'px';
        body.insertBefore(cobrandPaddingElement, body.firstChild);

        setCurrentNav();
        if(hasNav()) {
            const navInitialTop = getTop(document.getElementById(stickyNavSelector)) < 158 ? ' nav-top' : '';

            $currentNav.addClass('has-cobrand' + navInitialTop);
        }
    }

    // toggle stuck header with cobrand
    const toggleStuckCobrand = (hasNavTop) => {
        setCurrentNav();
        if(!hasNav()) {return;}
        if($currentNav.hasClass('nav-top') === hasNavTop) {return;}
        $currentNav.toggleClass('stuck-cobrand');
    };
    // end of cobrand

    // scrollbar measurement
    const scrollDiv = document.createElement('div');

    scrollDiv.className = 'scrollbar-measure';
    document.body.appendChild(scrollDiv);

    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

    document.body.removeChild(scrollDiv);
    // scrollbar measurement END

    // function scrollLock() {
        // $window.on('scroll touchmove mousewheel', function(e) {
            // $window.scrollTop(scrollTop);
        // });
    // }

    // function scrollUnlock() {
        // $window.off('scroll touchmove mousewheel');
    // }

    const main = $main.waypoint({
        handler: () => {
            $header.toggleClass('header--stuck');
            $body.toggleClass('has-stuck-header');
            toggleStuckCobrand(false);
        },
        offset: -54
    });

    $mainGroupsMenuTop.waypoint({
        handler: () => {
            $header.toggleClass('header--stuck');
            $body.toggleClass('has-stuck-header');
            toggleStuckCobrand(false);
        },
        offset: 38
    });

    $currentNav.waypoint({
        handler: () => {
            toggleStuckCobrand(true);
        },
        offset: 54
    });

    // main menu
    $menuBtn.on('click', function clickMenuBtn(e) {
        //  console.log('entro');
        e.preventDefault();

        const $this = $(this);
        const $images = $headerBottom.find('[data-src]');
        const $allLink = $mnNavbarList.find('.mn-navbar-link');
        const $allBlock = $('.js-mn-content-wrap');

        isMenuOpen = !isMenuOpen;

        $images.lazy();

        $allLink.removeClass('mn-navbar-link--active');
        $allBlock.removeClass('mn-content-wrap--open');
        $($($allLink)[0]).addClass('mn-navbar-link--active');
        $($($allBlock)[0]).addClass('mn-content-wrap--open');

        if ($(window).width() <= 767) {
            $('.js-mn-content-btn').parent().find('.mn-content').hide();
            // $('.js-mn-content-btn').parent('.mn-content-wrap--open-mb').find('.mn-content').hide();
        }else {
            $('.js-mn-content-btn').parent().find('.mn-content').fadeIn();
        }

        $allBlock.removeClass('mn-content-wrap--open-mb');

        $resortsBtn.removeClass('hm-resorts-btn--active');
        $mobileResorts.removeClass('mobile-resorts--open');
        $body.removeClass('has-open-resorts');
        $html.removeClass('has-open-resorts');

        $this.toggleClass('hm-menu-btn--active');
        $headerBottom.toggleClass('header-bottom--open');


        // if(isMenuOpen) {
        //     $body.on('mousewheel', function(e) {
        //         e.preventDefault();
        //         e.stopPropagation();
        //     });
        // } else {
        //     $body.off('mousewheel');
        // }
        
        if (isMenuOpen) {
            main[0].disable();

            $body.css({'margin-right': scrollbarWidth});
            $header.css({'left': '-' + scrollbarWidth + 'px'});
            resortMenu && (resortMenu.style.left = `-${scrollbarWidth}px`);

            scrollTop = !isResortsOpen ? window.scrollY : 0;
            isResortsOpen = false;
            $body.toggleClass('has-open-menu');
            $html.toggleClass('has-open-menu');
        } else {
            $body.css({'margin-right': 0});
            $header.css({'left': 0});
            resortMenu && (resortMenu.style.left = null);
            $body.toggleClass('has-open-menu');
            $html.toggleClass('has-open-menu');
            $window.scrollTop(scrollTop);

            main[0].enable();
        }


    });
    // main menu END

    // hide a main menu when the user clicks outside of it
    if (window.innerWidth > 1200) {
        $document.on('click touchstart', (e) => {
            if (!$header.is(e.target) && $header.has(e.target).length === 0 && isMenuOpen) {
                $menuBtn.trigger('click');
            }
        });
    }
    // hide a main menu when the user clicks outside of it END

    // resorts menu
    $resortsBtn.on('click', function clickResortsBtn(e) {
        e.preventDefault();

        const $this = $(this);
        const $images = $mobileResorts.find('[data-src]');

        isResortsOpen = !isResortsOpen;
        isMenuOpen = false;

        $images.lazy();

        $menuBtn.removeClass('hm-menu-btn--active');
        $headerBottom.removeClass('header-bottom--open');
        $body.removeClass('has-open-menu');
        $html.removeClass('has-open-menu');

        $this.toggleClass('hm-resorts-btn--active');
        $mobileResorts.toggleClass('mobile-resorts--open');
        $body.toggleClass('has-open-resorts');
        $html.toggleClass('has-open-resorts');

        if(isResortsOpen) {
            scrollTop = $window.scrollTop();
        } else {
            $window.scrollTop(scrollTop);
        }
    });
    // resorts menu END

    // $document.ready(function() {
    //     $stickyResortMenu.each(function(index, value) {
    //         new Waypoint.Sticky({
    //             element: value,
    //             offset: 50,
    //             stuckClass: 'stuck-rm-top'
    //         });
    //     });

    //     if($window.width() >= 1200) {
    //         $scroll.scrollbar({
    //             scrollStep: 20
    //         });
    //     }
    // });

    // mobile accordion
    $mnContentBtn.on('click', function clickContentBtn(e) {
        e.preventDefault();

        const $this = $(this);
        const $parent = $this.parent();

        $parent.toggleClass('mn-content-wrap--open-mb');
        $parent.find('.mn-content').slideToggle(400);
    });
    // mobile accordion END

    // desktop accordion
    $mnNavbarList.on('click', '.mn-navbar-link', function clickNavbarList(e) {
        e.preventDefault();

        const $this = $(this);
        const $allBlock = $('.js-mn-content-wrap');
        const $allLink = $mnNavbarList.find('.mn-navbar-link');
        let $block;

        if ($(e.target).is('a')) {
            $block = $($(e.target).attr('href'));
        } else {
            $block = $($(e.target).parents('a').attr('href'));
        }

        $allLink.removeClass('mn-navbar-link--active');
        $this.addClass('mn-navbar-link--active');
        $allBlock.removeClass('mn-content-wrap--open');
        $block.addClass('mn-content-wrap--open');
    });
    // desktop accordion END

    // custom select
    $customSelects.each( function eachCustomSelects(key, item) {
        $(item).selectmenu({
            appendTo: $(this).parents('.check-rates')
        });
    });

    // Quick Quote
    $bookNowButton.on('click', () => {
        const $menuCont = $('.header-bottom ');

        $bookNowButton.addClass('active');
        $menuBtn.removeClass('hm-menu-btn--active');
        $menuCont.removeClass('js-header-bottom header-bottom--open');
        $html.removeClass('has-open-menu');
        $body.removeClass('has-open-menu').attr('style', '');
        $header.attr('style', '');
    });

    $bookNowOverlay.on('click', () => {
        $bookNowButton.removeClass('active');
    });

    // for (let index = 0; index < quickQuoteElms.length; index++) {
    //     ReactDOM.render(
    //         <QuickQuote
    //             className="qq-rst-menu"
    //             quoteButtonText="Get Quote"
    //             controlThemeClassName="control-small dark-side"
    //         />,
    //         quickQuoteElms[index]
    //     );
    // }

    //  LANGUAGE
    class ShowLanguagePanel {
        constructor(langBtnClass, langBlockClass) {
            this.langBtnClass = langBtnClass;
            this.langBlockClass = langBlockClass;
            this.langBtn = document.querySelector(`.${langBtnClass}`);
            this.langBlock = document.querySelector(`.${langBlockClass}`);
            this.btnIcon = this.langBtn.querySelector('i');
            this._events();
        }

        _events() {
            this.langBtn.addEventListener('click', e => {
                e.preventDefault();
                this.langBlock.classList.toggle(`${this.langBlockClass}--show`);
                // if(window.innerWidth < 767) {
                this.btnIcon.classList.toggle('ic-close');
                this.btnIcon.classList.toggle('ic-globe');
                // }
            });

            window.addEventListener('resize', () => {
                this.langBlock.classList.remove(`${this.langBlockClass}--show`);
                this.btnIcon.classList.remove('ic-close');
                this.btnIcon.classList.add('ic-globe');
            });


        }
    }

    document.body.addEventListener('click', e => {
        const langBtn = document.querySelector('.js-lang-btn');

        if(e.target !== langBtn
            && e.target !== document.querySelector('.js-lang-btn-turnoff')
            && document.querySelector('.ht-country-list--show')) {
            if($(e.target).closest('.ht-country-list')) {
                document.querySelector('.ht-country-list').classList.remove('ht-country-list--show');
                langBtn.querySelector('i').className = 'ic ic-globe';
            }
        }
    });


    document.querySelector('.js-lang-btn') && new ShowLanguagePanel('js-lang-btn', 'ht-country-list');
    document.querySelector('.js-lang-btn-mobile') && new ShowLanguagePanel('js-lang-btn-mobile', 'ht-country-list-mobile');
    document.querySelector('.js-lang-btn-turnoff') && new ShowLanguagePanel('js-lang-btn-turnoff', 'ht-country-list');

    // Subscribe Newsletter Popup Component
    ReactDOM.render(
        <SubscribePopup />,
        document.getElementById('subscribe-popup')
    );

};

// Reopening Toolbar
const openDetails = document.querySelector('#btn-featured-display');
const featureDetails = document.querySelector('.featured-display');
const btnArrow = document.querySelector('#btn-arrow');
const featuredTop = document.querySelector('.featured-top');

openDetails.addEventListener('click', e => {
    e.preventDefault();
    featureDetails.classList.toggle('open');
    btnArrow.classList.toggle('ic-button-arrow-up');
    btnArrow.classList.toggle('ic-button-arrow-down');
    featuredTop.classList.toggle('close');
});

export default header;

// function touchyhandler(e) {
//     e.preventDefault();
// }

// to add
// document.addEventListener('touchmove', touchyhandler, false);
// to remove
// document.removeEventListener('touchmove', touchyhandler);

// function removeFocus() {
//     $('document').find('*').blur();
// }

// document.addEventListener('touchmove', removeFocus);
