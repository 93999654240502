import '~/js/libs/quickquote/chosenjquerymin';
import '~/js/libs/quickquote/jqdeserialize';
import '~/js/libs/quickquote/fancybox';
import '~/js/libs/quickquote/flatpickr-248';
import moment from './moment-with-locales-2.17.1';
// import Cookies from './jscookie220';

/* eslint-disable*/

(function () {
  var sandalssearchform = {
    init: function (formselector, overlayselector) {
      var formQQ = this;
      this.searchform = $(formselector);
      this.overlay = $(overlayselector);

      this.searchagain = this.searchform.find('#searchagain').length ? true : false;

      this.changedeparture = this.changedeparture.bind(this);
      this.changedestination = this.changedestination.bind(this);
      this.changeduration = this.changeduration.bind(this);
      this.changeroomcount = this.changeroomcount.bind(this);
      this.changesearchtype = this.changesearchtype.bind(this);
      this.changesearchtypeSpan = this.changesearchtypeSpan.bind(this);
      this.changesearchtypeagent = this.changesearchtypeagent.bind(this);
      this.changesearchtypetoflighthotel = this.changesearchtypetoflighthotel.bind(this);
      this.changesearchtypetohotelonly = this.changesearchtypetohotelonly.bind(this);
      this.closeoverlay = this.closeoverlay.bind(this);
      this.datepickerchange = this.datepickerchange.bind(this);
      this.datepickerclose = this.datepickerclose.bind(this);
      this.datepickercreateday = this.datepickercreateday.bind(this);
      this.initchosen = this.initchosen.bind(this);
      this.initdatepicker = this.initdatepicker.bind(this);
      this.load = this.load.bind(this);
      this.opendatepicker = this.opendatepicker.bind(this);
      this.save = this.save.bind(this);
      this.submit = this.submit.bind(this);
      this.updateprices = this.updateprices.bind(this);
      this.validate = this.validate.bind(this);

      this.closedby = null;
      this.firstopen = true;
      this.prices = {};

      this.datepicker = this.initdatepicker(this.searchform.find('.flatpickr'));


      this.initchosen(this.searchform.find('select'));

      this.load();

      this.searchform.find('#depart').on('change', this.changedeparture);
      this.searchform.find('#hotelcode').on('change', this.changedestination);
      this.searchform.find('input[name="roomgroups"]').on('change', this.changeroomcount);
      this.searchform.find('.searchtabs button').on('click', this.changesearchtype);
      this.searchform.find('.searchtabs button span').on('click', this.changesearchtypeSpan);
      this.searchform.find('.searchtabs__agent button').on('click', this.changesearchtypeagent);
      this.searchform.find('#dateinput').on('click', this.opendatepicker);
      this.overlay.find('.date-selection__option').on('change', this.changeduration);
      this.overlay.find('.overlay__div-closebutton, .date-selection__apply').on('click', this.closeoverlay);

      if (!this.searchagain) this.searchform.on('submit', this.submit);
      $('.combined_searchform').find('.dsc-quote-btn').on('click', function (e) {
        e.preventDefault();
        $('.combined_searchform').submit();
        return false;
      });
    },

    initchosen: function (selector) {
      return $(selector).chosen({
        width: "100%",
        disable_search: true
      });
    },

    initdatepicker: function (selector) {
      return $(selector).flatpickr({
        mode: 'range',
        dateFormat: "j F y",
        minDate: moment().add(3, 'days').startOf('day').toDate(),
        onClose: this.datepickerclose,
        onDayCreate: this.datepickercreateday,
        onMonthChange: this.updateprices,
        onChange: this.datepickerchange,
        appendTo: this.overlay.find('#js-date-selection__calendar')[0],
        inline: true,
        animate: false
      });
    },

    changedeparture: function (event) {
      var input = $(event.target);
      var selection = input.val();

      this.searchform.find('#originiata').val(selection);
      this.searchform.find('#depair').val(selection);
    },

    changedestination: function (event) {
      var input = $(event.target);
      var selection = input.val();

      this.searchform.find('#destair').val(this.hotelcodemapping[selection]);
      this.searchform.find('#destinationiata').val(this.hotelcodemapping[selection]);
    },

    changeduration: function (event) {
      var selecteddates = this.datepicker.selectedDates;
      var selecteddateslabel = this.overlay.find('.date-selection__selected-dates');
      var selectionoption = this.overlay.find('.date-selection__option:checked').val();

      selecteddateslabel.html('');
      this.datepicker.clear();

      if (selecteddates.length == 2 && selectionoption !== 'other') {
        var nights = parseInt(event.target.value);
        selecteddates[1] = moment(selecteddates[0]).add(nights, 'days').toDate();

        this.datepicker.setDate(selecteddates, true);
      }

      this.updateprices();
    },

    changeroomcount: function (event) {
      var input = $(event.target);

      var rooms = parseInt(input.val());
      var adults = rooms * 2;

      this.searchform.find('#roomcount').val(rooms.toString());
      this.searchform.find('#adults').val(adults.toString());
    },

    changesearchtypeSpan: function (event) {
      $(event.target).removeClass('active');
      var button = $(event.target).parent();
      if (button.hasClass('active')) {
        return false;
      }
      else {
        if (button.hasClass('button__searchtype--flighthotel')) {
          $('.searchtabs').find('button').each(function () {
            $(this).removeClass('active');
          });
          button.addClass('active');
          this.changesearchtypetoflighthotel(false);
        } else {
          $('.searchtabs button').each(function () {
            $(this).removeClass('active');
          });
          button.addClass('active');
          this.changesearchtypetohotelonly(false);
        }
      }
    },

    changesearchtype: function (event) {
      var button = $(event.target);
      var type = event.target.nodeName.toLowerCase();
      if ('span' === type) {
        var button = $(event.target).parent();
      }

      if (button.hasClass('active')) {
        return false;
      }
      else {
        if (button.hasClass('button__searchtype--flighthotel')) {
          $('.searchtabs').find('button').each(function () {
            $(this).removeClass('active');
          });
          button.addClass('active');
          this.changesearchtypetoflighthotel(false);
        } else {
          $('.searchtabs button').each(function () {
            $(this).removeClass('active');
          });
          button.addClass('active');
          this.changesearchtypetohotelonly(false);
        }
      }
    },

    changesearchtypeagent: function (event) {
      var button = $(event.target);

      if (button.hasClass('active')) {
        return;
      }

      if (button.hasClass('button__searchtype--flighthotel')) {
        this.changesearchtypetoflighthotel(true);
      } else {
        this.changesearchtypetohotelonly(true);
      }
    },

    changesearchtypetoflighthotel: function (isagent) {
      //				this.searchform.find('.button__searchtype--flighthotel').addClass('active');
      //				this.searchform.find('.button__searchtype--hotelonly').removeClass('active');

      this.searchform.find('#product-flight').attr('disabled', false);
      this.searchform.find('#product-hotel').attr('disabled', false);
      this.searchform.find('#searchtype').val('flighthotel');
      this.searchform.find('#product').val('multisearch');
      this.searchform.find('#multisearch').val('1');
      this.searchform.find('#redirecturl').val(isagent ? '/resorts-agent' : '/resorts');

      this.searchform.find('.searchoption__departfield').slideToggle();
      this.searchform.find('.searchoption__flightclass').show();
      // this.searchform.find('.searchoption__flightclass').animate({width:'toggle'},'50%');
      this.searchform.find('.searchoption__promocode').toggleClass("searchoption__promocode-slideleft", 1000, "easeOutSine");
    },

    changesearchtypetohotelonly: function (isagent) {
      //				this.searchform.find('.button__searchtype--flighthotel').removeClass('active');
      //				this.searchform.find('.button__searchtype--hotelonly').addClass('active');

      this.searchform.find('.searchoption__departfield').slideToggle();
      this.searchform.find('.searchoption__flightclass').hide();
      // this.searchform.find('.searchoption__flightclass').animate({width:'toggle'},0);
      this.searchform.find('.searchoption__promocode').toggleClass("searchoption__promocode-slideleft", 1000, "easeOutSine");

      this.searchform.find('#product-flight').attr('disabled', true);
      this.searchform.find('#product-hotel').attr('disabled', true);
      this.searchform.find('#searchtype').val('hotel');
      this.searchform.find('#product').val('hotel');
      this.searchform.find('#multisearch').val('0');
      this.searchform.find('#redirecturl').val(isagent ? '/resort-agent' : '/resort');
      this.searchform.find('#depair').val('');
    },

    closeoverlay: function (event) {
      event.preventDefault();

      // automatically do a search if we are from search again form
      if (this.searchagain && !$(event.target).hasClass('overlay__div-closebutton')) {
        var selecteddates = this.datepicker.selectedDates;

        if (selecteddates.length == 2) {
          var startdate = moment(selecteddates[0]);
          var enddate = moment(selecteddates[1]);

          var nights = enddate.diff(startdate, 'days');

          this.searchform.find('#depdate').val(startdate.format('YYYY-MM-DD'));
          this.searchform.find('#depdate-year').val(startdate.format('YYYY'));
          this.searchform.find('#depdate-month').val(startdate.format('MM'));
          this.searchform.find('#depdate-day').val(startdate.format('DD'));

          this.searchform.find('#startdate').val(startdate.format('YYYY-MM-DD'));
          this.searchform.find('#startdate-year').val(startdate.format('YYYY'));
          this.searchform.find('#startdate-month').val(startdate.format('MM'));
          this.searchform.find('#startdate-day').val(startdate.format('DD'));

          this.searchform.find('#enddate').val(enddate.format('YYYY-MM-DD'));
          this.searchform.find('#enddate-year').val(enddate.format('YYYY'));
          this.searchform.find('#enddate-month').val(enddate.format('MM'));
          this.searchform.find('#enddate-day').val(enddate.format('DD'));

          this.searchform.find('#nights').val(nights);
        }

        this.searchform.submit();
      } else {
        this.overlay.slideUp('fast');
        this.closedby = 'closebutton';

        this.datepicker.close();
      }
      if ($('body').hasClass('noscroll')) {
        $('body').removeClass('noscroll');
      }
    },

    datepickerclose: function (selecteddates, datestring, instance) {
      if (!selecteddates.length) {
        if (this.closedBy == 'closebutton') {
          this.closedBy = null;
        } else {
          this.datepicker.open();
        }
        if ($('body').hasClass('noscroll')) {
          $('body').removeClass('noscroll');
        }
        return;
      }

      if (this.closedBy == 'closebutton') {
        this.closedby = null;
      } else {
        this.datepicker.open();
      }

      if (selecteddates.length == 2) {
        var dates = datestring.split(" to ", 2);

        var formatteddepartdate = moment(dates[0], 'DD MMMM YYYY').format('YYYY-MM-DD');
        var formattedreturndate = moment(dates[1], 'DD MMMM YYYY').format('YYYY-MM-DD');
        var departdate = formatteddepartdate.split("-", 3);
        var returndate = formattedreturndate.split("-", 3);

        var nights = moment(formattedreturndate).diff(moment(formatteddepartdate), 'days');

        this.searchform.find('#depdate').val(formatteddepartdate);
        this.searchform.find('#depdate-year').val(departdate[0]);
        this.searchform.find('#depdate-month').val(departdate[1]);
        this.searchform.find('#depdate-day').val(departdate[2]);

        this.searchform.find('#startdate').val(formatteddepartdate);
        this.searchform.find('#startdate-year').val(departdate[0]);
        this.searchform.find('#startdate-month').val(departdate[1]);
        this.searchform.find('#startdate-day').val(departdate[2]);

        this.searchform.find('#enddate').val(formattedreturndate);
        this.searchform.find('#enddate-year').val(returndate[0]);
        this.searchform.find('#enddate-month').val(returndate[1]);
        this.searchform.find('#enddate-day').val(returndate[2]);

        this.searchform.find('#nights').val(nights);
        this.searchform.find('#nights').trigger('chosen:updated');

        this.overlay.find('#nightscalendar').val(moment(formatteddepartdate).format('Do MMM YY') + ' - ' + moment(formattedreturndate).format('Do MMM YY') + ', ' + nights + ' Nights');
        this.overlay.find('.overlaymessage__applybutton').show();
      }
    },

    datepickerchange: function (selecteddates, _datestr, instance) {
      var selectionoption = this.overlay.find('.date-selection__option:checked').val();
      var searchtype = this.searchform.find('#searchtype').val();
      var selecteddateselement = this.overlay.find('.date-selection__selected-dates');

      if (this.searchagain) {
        var dateinput = this.searchform.find('#dateinput');
        dateinput.val(dateinput.attr('placeholder'));
      }

      if (selecteddates.length === 1 && selectionoption !== 'other') {
        var diff = moment(selecteddates[0]).diff(moment(), 'days');

        if (searchtype !== 'hotel' && diff < 270 && !this.prices.hasOwnProperty(moment(selecteddates[0]).format('MMMM D, YYYY'))) {
          instance.clear();
          return;
        }

        if (selectionoption !== 'other') {
          var start = selecteddates[0];
          var end = moment(selecteddates[0]).add(selectionoption, 'days').toDate();

          instance.setDate([start, end], true);

          selecteddateselement.html(moment(start).format('dddd DD/MM/YYYY') + ' - ' + moment(end).format('dddd DD/MM/YYYY'));
        }
      }

      if (selecteddates.length === 2) {
        selecteddateselement.html(moment(selecteddates[0]).format('dddd DD/MM/YYYY') + ' - ' + moment(selecteddates[1]).format('dddd DD/MM/YYYY'));
      }
    },

    datepickercreateday: function (dObj, dStr, fp, dayelement) {
      var date = dayelement.getAttribute('aria-label');
      var searchtype = this.searchform.find('#searchtype').val();
      var html = '';

      if ($('.date-selection__option:checked').val() === 'other' || searchtype === 'hotel') {
        return;
      }

      var diff = moment(dayelement.dateObj).diff(moment(), 'days');

      if (date && this.prices[date]) {
        html = '<span class="date-selection__day">' + dayelement.innerHTML + '</span>'
          + '<span class="date-selection__price"> ' + this.currency + Number(this.prices[date].price).toLocaleString() + '</span>';

        if (this.prices[date].cheapest) {
          html += '<span class="event date-selection__cheapest-icon"></span>';
          $(dayelement).addClass('flatpicker-day--cheapest');
        }
      } else {
        if (diff < 270) {
          $(dayelement).addClass('flatpickr-day--no-availability');
          html = '<span class="date-selection__day">' + dayelement.innerHTML + '</span>'
            + '<span class="date-selection__price">Not<br/>Available</span>';
        } else {
          html = '<span class="date-selection__day">' + dayelement.innerHTML + '</span>'
            + '<span class="date-selection__price">Select</span>';
        }
      }

      if (html !== '') {
        dayelement.innerHTML = html;
      }
    },

    load: function () {
      // console.log(sandals_app.page.resortCode);
      // var data = Cookies.get('searchform');

      // if(data) {
      //     this.searchform.deserialize(data);

      //     this.firstopen = false;

      //     var startdate = moment(this.searchform.find('#startdate').val()).toDate();
      //     var enddate = moment(this.searchform.find('#enddate').val()).toDate();

      //     if (!this.searchagain) {
      //         this.datepicker.setDate([startdate, enddate], true);
      //         this.searchform.find('select').trigger('chosen:updated');
      //     }
      // }
      if (sandals_app.page.resortCode !== undefined) {
        var defVal = '';
        $('#hotelcode option').each(function () {
          if (sandals_app.page.resortCode == $(this).data('code')) {
            defVal = $(this).attr('value');
          }
        });
        $('#hotelcode').val(defVal).trigger('chosen:updated');
        this.searchform.find('#destair').val(this.hotelcodemapping[defVal]);
        this.searchform.find('#destinationiata').val(this.hotelcodemapping[defVal]);
      }
      $('#depart').val('').trigger('chosen:updated');
      $('#dateinput').val('');

      var searchType = this.searchform.find('#searchtype').val();

      setTimeout(function () {

        if (searchType === 'hotel') {
          $('.button__searchtype--hotelonly').trigger('click');
        }
        else {
          $('.button__searchtype--flighthotel').trigger('click');
        }
      }, 1000);
    },

    opendatepicker: function () {
      var selectedflightclass = this.searchform.find('#flightclass option:selected').text();
      var searchtype = this.searchform.find('#searchtype').val();

      if (!this.searchagain) {
        if (searchtype === 'flighthotel' && this.searchform.find('#depart').val() === '') {
          alert('Please select a departure');
          return;
        } else if (this.searchform.find('#hotelcode').val() === '') {
          alert('Please select a hotel');
          return;
        }
      }

      if (searchtype === 'hotel') {
        this.overlay.find('.flight-text').hide();
      } else {
        this.overlay.find('.flight-text').show();
      }

      this.overlay.find('.date-selection__selected-departure').text(this.searchform.find('#depart option:selected').text());
      this.overlay.find('.date-selection__selected-destination').text(this.searchform.find('#hotelcode option:selected').text());
      this.overlay.find('.date-selection__selected-class').text(selectedflightclass === 'Any' ? '' : selectedflightclass);

      if (this.firstopen) {
        this.datepicker.jumpToDate(moment().startOf('month').add(1, 'month').toDate());
        this.firstopen = false;
      }

      this.updateprices();
      this.datepicker.open();

      if (!$('body').hasClass('noscroll')) {
        $('body').addClass('noscroll');
      }

      if (this.overlay.css('display') != 'block') {
        this.overlay.slideToggle('fast');
      }
    },

    save: function () {
      var data = this.searchform.serialize();
      // Cookies.set('searchform', data);
    },

    submit: function (event) {
      event.preventDefault();

      var adults = 0;
      var children = 0;
      var infants = 0;

      var rooms = $('#roomcount').val();

      for (var i = 1; i <= rooms; i++) {
        adults += parseInt(this.searchform.find('#adults-' + i).val());
        children += parseInt(this.searchform.find('#children-' + i).val());
        infants += parseInt(this.searchform.find('#infants-' + i).val());
      }

      if (this.validate() === true) {
        this.searchform.find('#adults').val(adults);
        this.searchform.find('#children').val(children);
        this.searchform.find('#infants').val(infants);

        this.save();

        this.searchform.get(0).submit();
      }
    },

    updateprices: function () {
      var dateoptionchecked = this.overlay.find('.date-selection__option:checked').val();
      var searchtype = this.searchform.find('#searchtype').val();

      if (dateoptionchecked === 'other' || searchtype === 'hotel') {
        this.prices = {};
        this.datepicker.redraw();
        return;
      }

      var depair = this.searchform.find('#depart').val();
      var type = this.searchform.find('#searchtype').val();
      var flightclass = this.searchform.find('#flightclass').val();
      var hotelcode = this.searchform.find('#hotelcode').val();

      var params = {
        depair: depair,
        type: type,
        flightclass: flightclass,
        hotelcode: hotelcode,
        nights: dateoptionchecked,
        month: this.datepicker.currentMonth + 1,
        year: this.datepicker.currentYear,
        site: 'tstnews.sandals.co.uk'
      };

      var options = {
        url: '//fusionapi.traveltek.net/2.0/json/sandalscalender.pl',
        method: 'GET',
        data: params
      };

      this.prices = {};

      $.ajax(options).done(function (data) {
        var cheapest = Number.POSITIVE_INFINITY;

        if (data.results.length) {
          this.currency = data.meta.defaultcurrency.symbol;

          for (var i = 0; i < data.results.length; i++) {
            var price = data.results[i];
            var date = price.date.split('-');
            date[1] -= 1;

            if (price.price < cheapest) { cheapest = Math.floor(price.price); }

            this.prices[moment(date).format('MMMM D, YYYY')] = { price: Math.floor(price.price) };
          }

          for (var key in this.prices) {

            var price = this.prices[key];

            price.cheapest = price.price === cheapest;
          }

          this.datepicker.redraw();
        }
      }.bind(this));
    },

    validate: function () {
      var searchtype = this.searchform.find('#searchtype').val();
      var validations = {};

      if (searchtype === 'flighthotel') {
        validations = {
          'depart': 'departure airport',
          'hotelcode': 'destination',
          'dateinput': 'travelling dates'
        };
      } else if (searchtype === 'hotel') {
        validations = {
          'hotelcode': 'destination',
          'dateinput': 'travelling dates'
        };
      }

      for (var key in validations) {
        if (this.searchform.find('#' + key).val() === '') {
          alert('Please select the ' + validations[key] + ' of your holiday.')
          return false;
        }
      }

      return true;
    },

    hotelcodemapping: {
      '37954': 'ANU',
      '37926': 'NAS',
      '37947': 'GGT',
      '66937': 'BGI',
      '75244': 'BGI',
      '64572': 'GND',
      '37928': 'MBJ,KIN',
      '37963': 'MBJ,KIN',
      '37950': 'MBJ,KIN',
      '37949': 'MBJ,KIN',
      '37953': 'MBJ,KIN',
      '37951': 'MBJ,KIN',
      '37948': 'MBJ,KIN',
      '37956': 'UVF',
      '37955': 'UVF',
      '37957': 'UVF',
      '37958': 'MBJ,KIN',
      '37960': 'MBJ,KIN',
      '37925': 'PLS'
    }
  }

  $(document).ready(function () {
    sandalssearchform.init('.combined_searchform', '.overlay__div');
    // $(window).resize(function(){
    //     sandalssearchform.init('.combined_searchform', '.overlay__div');
    // });
  });

  $('input[name="flight-searchtype"]').on("change", function () {
    $('#multisearch').val($("input[name='flight-searchtype']:checked").val());
    $('.searchoption__additionaldest--first').slideToggle();
    if ($('#multicentre:checked').length) {
      if ($('.add-destination--first').css("display", "none")) {
        $('.add-destination--first').slideDown();
      }
    } else {
      $('.searchoption__additionaldest').each(function () {
        $(this).slideUp();
      });
    }
  });

  $('.add-destination--first').on("click", function () {
    $('.searchoption__additionaldest--last').slideToggle();
    $(this).slideToggle();
  });

  $('.add-destination--last').on("click", function () {
    $('.searchoption__additionaldest--last').slideToggle();
    $('.add-destination--first').slideToggle();
  });
}());
