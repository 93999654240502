// Deps =========================================
import $ from 'jquery';
import 'babel-polyfill';
import 'formdata-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import _ from './modules/utils';
import 'whatwg-fetch';
import runGlobal from './modules/global';

// Query Selector ===============================
const query = (selector) => document.querySelector(selector);

const queryAll = (selector) => Array.from(document.querySelectorAll(selector));

// Setting Global Vars ==========================
Object.assign(window, { React, ReactDOM, _, query, queryAll, $, jQuery: $ });

// _bind Helper =================================
React.Component.prototype._bind = function _bind(...methods) {

    methods.forEach((method) => {

        this[method] = this[method].bind(this);
    });
};

// to-do: move to a separate file
// NodeList forEach polyfill ====================

if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}

// Modules ======================================
runGlobal();
