module.exports={
  "type": "object",
  "optional": true,
  "required": [
    "startedDateTime",
    "time",
    "request",
    "response",
    "cache",
    "timings"
  ],
  "properties": {
    "pageref": {
      "type": "string"
    },
    "startedDateTime": {
      "type": "string",
      "format": "date-time",
      "pattern": "^(\\d{4})(-)?(\\d\\d)(-)?(\\d\\d)(T)?(\\d\\d)(:)?(\\d\\d)(:)?(\\d\\d)(\\.\\d+)?(Z|([+-])(\\d\\d)(:)?(\\d\\d))"
    },
    "time": {
      "type": "number",
      "min": 0
    },
    "request": {
      "$ref": "#request"
    },
    "response": {
      "$ref": "#response"
    },
    "cache": {
      "$ref": "#cache"
    },
    "timings": {
      "$ref": "#timings"
    },
    "serverIPAddress": {
      "type": "string",
      "oneOf": [
        { "format": "ipv4" },
        { "format": "ipv6" }
      ]
    },
    "connection": {
      "type": "string"
    },
    "comment": {
      "type": "string"
    }
  }
}
