/**
		ALL JS
*/

// import '~/js/libs/quickquote/jqdeserialize';
// import '~/js/libs/quickquote/chosenjquerymin';
// import '~/js/libs/quickquote/flatpickr-248';
// import '~/js/libs/quickquote/fancybox';
import '~/js/libs/quickquote/sandalssearchformnew';

const $menuButton = $('.js-menu-btn');
const $bookNowButton = $('.js-hm-book-now');
const $bookNowButtonOverlay = $('.js-hm-book-now-overlay');
const $bookNowBox = $('.book-now-box');

const $mainQQ = $('.js-main-qq');
const $bookNowQQ = $('.js-hm-book-now-qq');
const $menuQQ = $('.js-menu-qq');

const startEvent = 'mousedown';
// const startEvent = (touch) ? 'touchstart' : 'mousedown';

const $initForm = $('form.combined_searchform');

// console.log($mainQQ.length);
if ($mainQQ.length > 0) {
	$initForm.appendTo($mainQQ);
}

$bookNowButton.on(startEvent, function (event) {
	event.stopPropagation();

	const $this = $(this);
	const $form = $('form.combined_searchform');

	const hasClickedOnCloseButton = $menuButton.hasClass('hm-menu-btn--active');

	if (hasClickedOnCloseButton) {
		$menuButton.trigger('click');
	}

	if ($(window).height() <= 710) {
		$('.book-now-box').css({
			height: '270px',
			'overflow-y': 'scroll'
		});
	}

	var windowHeight = $(window).height();

	/**
		TODO: Create a css class for this :'v
	*/

	if (windowHeight <= 710) {
		$bookNowBox.addClass('scroll-y')
			.css({
				height: (windowHeight - 150) + 'px',
			});
	} else if ($bookNowBox.height() < 710 && $bookNowBox.hasClass('scroll-y')) {
		$bookNowBox.removeClass('scroll-y')
			.css({
				height: 'auto',
			});;
	}

	if (!$this.hasClass('active')) {
		$this.addClass('active');
		$form.appendTo($bookNowQQ);
	}

});

$bookNowButtonOverlay.on(startEvent, function (event) {
	const $form = $('form.combined_searchform');

	if ($mainQQ.length > 0) {
		$form.appendTo($mainQQ);
	}
	$bookNowButton.removeClass('active');
});

$menuButton.on(startEvent, function (event) {

	const $form = $('form.combined_searchform');
	const $this = $(this);
	const hasClickedOnCloseButton = $this.hasClass('hm-menu-btn--active');

	// console.log('hasClickedOnCloseButton', hasClickedOnCloseButton);
	// console.log('hasClickedOnCloseButton', $this);


	if (hasClickedOnCloseButton) {
		if ($mainQQ.length > 0) {
			$form.appendTo($mainQQ);
		}
	} else {
		$form.appendTo($menuQQ);
	}

});

$(window).scroll(function () {
	if ($('body').hasClass('has-stuck-header')) {
		if ($bookNowButton.hasClass('active')) {
			const $form = $('form.combined_searchform');

			if ($mainQQ.length > 0) {
				$form.appendTo($mainQQ);
			}
			$bookNowButton.removeClass('active');
		}
	}
});