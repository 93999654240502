// SEARCH FUNCTIONALITY
// import $ from 'jquery';
import request from 'request';

/* eslint-disable */

const element = document.querySelector('.header');
// const elementMobile = document.querySelector('.header--stuck');

const subscriptionKey = 'f397f01d10d24455ac74670615b73154';
const customConfigId = '3455081199';
const suggestSearch = 'https://api.cognitive.microsoft.com/bingcustomsearch/v7.0/suggestions/search?';



const searchThis = (text) => {
  window.location = `/en/search/?q=${text}`;
};


const bingSuggest = (options, elem) => {
  try {
    request(options, (error, response, body) => {
      const searchResponse = JSON.parse(body);

      if (typeof searchResponse.suggestionGroups !== 'undefined' && searchResponse.suggestionGroups.length > 0) {
        let results = searchResponse.suggestionGroups[0].searchSuggestions;
        let suggestHtml = '';

        if (results.length > 0) {
          results.forEach((result) => {
            suggestHtml += `<li class="suggestedLi"><a href="" class="suggestedText">${result.displayText}</a>`;
          });
          $(elem).parent().find('.suggestions').html(suggestHtml).show();
        }
      }
      else {
        $(elem).parent().find('.suggestions').html('').hide();
      }
    }).on('error', function (err) {
      console.log(err);
    });
  } catch (err) {
    //DO NOTHING
  }
};



if (element) {
  let searchTerm;
  const bingSearch = document.querySelector('.bing-search');
  const bingSearchScroll = document.querySelector('.bing-searchScroll');
  const suggestions = document.querySelector('.suggestions');
  const searchIcon = document.getElementById('searchIcon');
  const searchIconScroll = document.getElementById('searchIconScroll');
  const input = document.getElementById('user-text');
  const inputScroll = document.getElementById('user-textScroll');

  let searchOptions = {
    url: suggestSearch + 'q=' + searchTerm + '&customconfig=' + customConfigId + '&count= 10',
    headers: {
      'Ocp-Apim-Subscription-Key': subscriptionKey
    }
  };

  window.addEventListener('scroll', function () {
    $('.bingSearch').find('.suggestions').hide();
  });

  input.addEventListener('keyup', (event) => {
    searchTerm = document.getElementById('user-text').value;

    if (event.keyCode === 13) {
      if ($.trim(searchTerm) !== '') {
        searchTerm = encodeURIComponent(searchTerm);
        window.location = `/en/search/?q=${searchTerm}`;
      }
    }
    else if ((event.keyCode !== 37) && (event.keyCode !== 38) && (event.keyCode !== 39) && (event.keyCode !== 40) && (event.keyCode !== 9)) {
      if (searchTerm !== '') {
        searchOptions.url = `${suggestSearch}q=${searchTerm}&customconfig=${customConfigId}`;
        bingSuggest(searchOptions, bingSearch);
      }
      else {
        $(bingSearch).parent().find('.suggestions').html('').hide();
      }
    }
    else if ((event.keyCode === 38) || (event.keyCode === 40)) {
      if ($('.suggestions').is(':visible')) {
        if (event.keyCode === 40) {
          if ($('.suggestions').find('.hovered').length === 0) {
            let $current = $('.suggestions').find('li:first');
            $current.addClass('hovered');
            $('.bing-search').val($current.find('a').text());
          }
          else {
            let $current = $('.suggestions').find('li.hovered').next();
            $('.suggestions').find('li').removeClass('hovered');
            $current.addClass('hovered');
            $('.bing-search').val($current.find('a').text());
          }
        }
        if (event.keyCode === 38) {
          if ($('.suggestions').find('.hovered').length === 0) {
            let $current = $('.suggestions').find('li:last');
            $current.addClass('hovered');
            $('.bing-search').val($current.find('a').text());
          }
          else {
            let $current = $('.suggestions').find('li.hovered').prev();
            $('.suggestions').find('li').removeClass('hovered');
            $current.addClass('hovered');
            $('.bing-search').val($current.find('a').text());
          }
        }
      }
      event.preventDefault();
    }
    else if (event.keyCode === 27) {
      $(bingSearch).parent().find('.suggestions').html('').hide();
    }
  });

  inputScroll.addEventListener('keyup', (event) => {
    searchTerm = document.getElementById('user-textScroll').value;
    if (event.keyCode === 13) {
      if ($.trim(searchTerm) !== '') {
        searchTerm = encodeURIComponent(searchTerm);
        window.location = `/en/search/?q=${searchTerm}`;
      }
    }
    else if ((event.keyCode !== 37) && (event.keyCode !== 38) && (event.keyCode !== 39) && (event.keyCode !== 40) && (event.keyCode !== 9)) {
      if (searchTerm !== '') {
        searchOptions.url = `${suggestSearch}q=${searchTerm}&customconfig=${customConfigId}`;
        bingSuggest(searchOptions, bingSearchScroll);
      }
      else {
        $(bingSearchScroll).parent().find('.suggestions').html('').hide();
      }
    }
    else if ((event.keyCode === 38) || (event.keyCode === 40)) {
      if ($('.suggestions').is(':visible')) {
        if (event.keyCode === 40) {
          if ($('.suggestions').find('.hovered').length === 0) {
            let $current = $('.suggestions').find('li:first');
            $current.addClass('hovered');
            $('.bing-searchScroll').val($current.find('a').text());
          }
          else {
            let $current = $('.suggestions').find('li.hovered').next();
            $('.suggestions').find('li').removeClass('hovered');
            $current.addClass('hovered');
            $('.bing-searchScroll').val($current.find('a').text());
          }
        }
        if (event.keyCode === 38) {
          if ($('.suggestions').find('.hovered').length === 0) {
            let $current = $('.suggestions').find('li:last');
            $current.addClass('hovered');
            $('.bing-searchScroll').val($current.find('a').text());
          }
          else {
            let $current = $('.suggestions').find('li.hovered').prev();
            $('.suggestions').find('li').removeClass('hovered');
            $current.addClass('hovered');
            $('.bing-searchScroll').val($current.find('a').text());
          }
        }
      }
      event.preventDefault();
    }
    else if (event.keyCode === 27) {
      $(bingSearchScroll).parent().find('.suggestions').html('').hide();
    }
  });

  const clickEventType = ((document.ontouchstart !== null) ? 'click' : 'touchstart');

  document.addEventListener(clickEventType, (event) => {

    if (event.target.classList.contains('searchButton') || event.target.id === 'searchIcon') {
      bingSearch.classList.remove('hide');
      bingSearch.classList.add('visible');
      searchIcon.classList.add('visible-searchIcon');
      bingSearch.focus();
      return;
    }

    if (event.target.classList.contains('searchButtonScroll') || event.target.id === 'searchIconScroll') {
      bingSearchScroll.classList.remove('hide');
      bingSearchScroll.classList.add('visible');
      searchIconScroll.classList.add('visible-searchIconScroll');
      bingSearchScroll.focus();
      return;
    }

    if (event.target.classList.contains('suggestedText')) {
      $(event.target).parents('.bingSearch').find('input').val(event.target.text);
      suggestions.style.display = 'none';
      searchTerm = encodeURIComponent(searchTerm);
      window.location = `/en/search/?q=${event.target.text}`;
      event.preventDefault();
    }

    if (!event.target.classList.contains('bing-search') && !event.target.classList.contains('searchButton') && bingSearch.classList.contains('visible') && !event.target.classList.contains('suggestions') && !event.target.classList.contains('suggestedLi')) {
      bingSearch.classList.add('hide');
      bingSearch.classList.remove('visible');
      searchIcon.classList.remove('hide');
      searchIcon.classList.remove('visible-searchIcon');
      searchIcon.classList.add('searchIcon');
      $('.bingSearch').find('.suggestions').hide();
    }

    // When scrolled down the page
    if (!event.target.classList.contains('bing-searchScroll') && !event.target.classList.contains('searchButtonScroll') && bingSearchScroll.classList.contains('visible') && !event.target.classList.contains('suggestions') && !event.target.classList.contains('suggestedLi')) {
      bingSearchScroll.classList.add('hide');
      bingSearchScroll.classList.remove('visible');
      searchIconScroll.classList.remove('hide');
      searchIconScroll.classList.remove('visible-searchIconScroll');
      searchIconScroll.classList.add('searchIconScroll');
      $('.bingSearch').find('.suggestions').hide();
    }
  });

  document.addEventListener('mousedown', (e) => {
    if (e.target.classList.contains('visible-searchIcon') && document.getElementById('user-text').value !== '') {
      searchTerm = document.getElementById('user-text').value;
      searchTerm = encodeURIComponent(searchTerm);
      window.location = `/en/search/?q=${searchTerm}`;
    }
  });

  document.addEventListener('mouseover', (e) => {
    let $thisElement = $(e.target);
    if (e.target.classList.contains('suggestedText')) {
      $thisElement.parents('.bingSearch').find('.suggestedLi').removeClass('hovered');
      $thisElement.parent().addClass('hovered');
    }
  });

  document.addEventListener('mousedown', (e) => {
    if (e.target.classList.contains('visible-searchIconScroll') && document.getElementById('user-textScroll').value !== '') {
      searchTerm = document.getElementById('user-textScroll').value;
      if ($.trim(searchTerm) !== '') {
        searchTerm = encodeURIComponent(searchTerm);
        window.location = `/en/search/?q=${searchTerm}`;
      }
    }
  });

}
