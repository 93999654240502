module.exports={
  "type": "object",
  "required": [
    "log"
  ],
  "properties": {
    "log": {
      "$ref": "#log"
    }
  }
}
