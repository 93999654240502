// import $ from 'jquery';
import '~/js/libs/jquery.lazy';
import 'jquery.scrollbar';
import header from '~/js/modules/header';
import modal from '~/js/modules/magnific-popup';
import '~/js/modules/share-social';
import imgLoad from '~/js/modules/progressiveImgLoad';
import lazyImg from '~/js/modules/lazyImg';

// Not really sure if you need it globally
import '~/js/libs/jquery-ui.min.js';

/** `internals` defines a local namespace and defaults for this module. */
const internals = {};

/** General event binding */
internals.bindEvents = () => {

    $(document).on('click', '.js-magnific-popup', () => {

        const $contentToScroll = $('.js-modal-scroll');
        // const $jsLazy = $('.js-lazy');

        // Lazy Loading for all images
        // $jsLazy.Lazy({
            // delay: 0
        // });

        // scrollStep: when the mouse is over the scrollbar (scroll emulating mode)
        $contentToScroll.scrollbar({
            scrollStep: 20
        });
    });
};

/** Third party stuff */
internals.thirdParty = () => {};

const global = () => {
    const { bindEvents, thirdParty } = internals;

    header();
    modal();
    bindEvents();
    thirdParty();
    imgLoad();
    lazyImg();
};

export default global;
