// == Create links for the social
const SocialShare = {};
export default SocialShare;
SocialShare.generateUrl = function (link, opt) {
    
    let prop;
    let arg;
    let argNe;

    for (prop in opt) {
        arg = '{' + prop + '}';
        if (link.indexOf(arg) !== -1) {
            link = link.replace(new RegExp(arg, 'g'), encodeURIComponent(opt[prop]));
        }
        argNe = '{' + prop + '-ne}';
        if (link.indexOf(argNe) !== -1) {
            link = link.replace(new RegExp(argNe, 'g'), opt[prop]);
        }
    }

    return this.cleanUrl(link);
};

SocialShare.cleanUrl = (fullUrl) => {

    fullUrl = fullUrl.replace(/\{([^{}]*)}/g, '');

    const params = fullUrl.match(/[^\=\&\?]+=[^\=\&\?]+/g);
    let link = fullUrl.split('?')[0];

    if (params && params.length > 0) {
        link += '?' + params.join('&');
    }

    return link;
};

SocialShare.doPopup = (e, href) => {
    e: (e ? e : window.event);
    const t = (e.target ? e.target : e.srcElement);
    const width = t.data - width || 800;
    const height = t.data - height || 500;

    const px = Math.floor(((screen.availWidth || 1024) - width) / 2);
    const py = Math.floor(((screen.availHeight || 700) - height) / 2);

    const popup = window.open(href, 'social',
        'width=' + width + ',height=' + height +
        ',left=' + px + ',top=' + py +
        ',location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1');

    if (popup) {
        popup.focus();
        if (e.preventDefault) { e.preventDefault(); }
        e.returnValue = false;
    }

    return !!popup;
};

// == Create links for the Facebook
$('.js-share-facebook').on('click', (e) => {

    let ogDescription = $("meta[property='og:description']").attr('content');
    let ogImg = $("meta[property='og:image']").attr('content');

    if (ogDescription === undefined) {
        ogDescription = 'Sandals Resorts';
    }

    if (ogImg === undefined) {
        ogImg = '//cdn.sandals.com/sandals/v12/images/home/portrait-share.jpg';
    }

    const params = {
        link: 'http://www.sandals.com' + location.pathname,
        redirect_url: 'https://www.sandals.com',
        img: ogImg,
        desc: ogDescription,
        app_id: '150389325070106'
    };
    const link = SocialShare.generateUrl('https://www.facebook.com/sharer.php?s=100&p[url]={link}&p[images][0]={img}&p[title]={title}&p[summary]={desc}', params);
    //  const link = SocialShare.generateUrl('https://www.facebook.com/dialog/share?app_id={app_id}&display=page&href={link}&redirect_uri={redirect_link}', params);

    $('.js-share-facebook').children().attr('href', link);
    SocialShare.doPopup(e, link);
});

// == Create links for the Pinterest
$('.js-share-pinterest').on('click', (e) => {

    let ogDescription = $("meta[property='og:description']").attr('content');
    let ogImg = $("meta[property='og:image']").attr('content');

    if (ogDescription === undefined) {
        ogDescription = 'Sandals Resorts';
    }

    if (ogImg === undefined) {
        ogImg = '//cdn.sandals.com/sandals/v12/images/home/portrait-share.jpg';
    }

    const params = {
        link: 'http://www.sandals.com' + location.pathname,
        img: ogImg,
        title: ogDescription
    };
    const link = SocialShare.generateUrl('https://pinterest.com/pin/create/bookmarklet/?media={img}&url={link}&is_video={is_video}&description={title}', params);
    //   const link = "https://pinterest.com/pin/create/bookmarklet/?media=" + ogImg + "&url=" + location.href + "&description=" + encodeURIComponent(ogDescription);

    $('.js-share-pinterest').children().attr('href', link);
    SocialShare.doPopup(e, link);
});

// == Create links for the twitter
$('.js-share-twitter').on('click', (e) => {

    let ogDescription = $("meta[property='og:description']").attr('content');

    if (ogDescription === undefined) {
        ogDescription = 'Sandals Resorts';
    }

    const params = {
        link: 'http://www.sandals.com' + location.pathname,
        title: ogDescription
    };
    const link = SocialShare.generateUrl('https://twitter.com/intent/tweet?url={link}&text={title}&via={via}&hashtags={hashtags}', params);

    $('.js-share-twitter').children().attr('href', link);
    SocialShare.doPopup(e, link);
});

// == Create links for the googlePlus
$('.js-share-googlePlus').on('click', (e) => {

    const params = {
        link: 'http://www.sandals.com' + location.pathname
    };
    const link = SocialShare.generateUrl('https://plus.google.com/share?url={link}', params);

    $('.js-share-googlePlus').children().attr('href', link);
    SocialShare.doPopup(e, link);
});
