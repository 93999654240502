module.exports={
  "properties": {
    "beforeRequest": {
      "$ref": "#cacheEntry"
    },
    "afterRequest": {
      "$ref": "#cacheEntry"
    },
    "comment": {
      "type": "string"
    }
  }
}
