/* ========================================================================
 * Bootstrap: index.js v3.3.7
 * http://getbootstrap.com/javascript/
 * ========================================================================
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * ======================================================================== */

import './includes/tooltip';
import './includes/popover';
import './includes/collapse';
import './includes/tab';
import './includes/scrollspy';
import './includes/affix';
