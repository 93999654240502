module.exports={
  "oneOf": [{
    "type": "object",
    "optional": true,
    "required": [
      "lastAccess",
      "eTag",
      "hitCount"
    ],
    "properties": {
      "expires": {
        "type": "string"
      },
      "lastAccess": {
        "type": "string"
      },
      "eTag": {
        "type": "string"
      },
      "hitCount": {
        "type": "integer"
      },
      "comment": {
        "type": "string"
      }
    }
  }, {
    "type": null,
    "additionalProperties": false
  }]
}
