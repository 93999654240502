const lazyImg = () => {

  ;(function($) {

   $.fn.isInViewport = function() {
    var elementTop = $(this).offset().top - 400;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  $.fn.unveil = function(threshold, callback) {

    var $w = $(window),
    th = threshold || 0,
    retina = window.devicePixelRatio > 1,
    attrib = retina? "data-src-retina" : "data-src",
    images = this,
    loaded;

    this.one("unveil", function() {
      var source = this.getAttribute(attrib);
      source = source || this.getAttribute("data-src");
      if (source) {
        this.setAttribute("src", source);
        if (typeof callback === "function") callback.call(this);
      }
    });

    function unveil() {
      var inview = images.filter(function() {
        var $e = $(this);
        if ($e.is(":hidden")) return;

        var wt = $w.scrollTop(),
        wb = wt + $w.height(),
        et = $e.offset().top,
        eb = et + $e.height();

        return eb >= wt - th && et <= wb + th;
      });

      loaded = inview.trigger("unveil");
      images = images.not(loaded);
    }

    $w.on("scroll.unveil resize.unveil lookup.unveil", unveil);

    unveil();

    return this;

  };

})(window.jQuery || window.Zepto);


if($('.sprite')[0]) {
  function spriteLoad() {

    const sections = document.querySelectorAll('.sprite');

    if (document.querySelectorAll('.sprite:not(.visible)').length === 0) return;

    for (const section of sections) {
      if (section.getBoundingClientRect().top - 400 <= window.innerHeight && section.getBoundingClientRect().top - 400 > 0) {
        $('.sprite').addClass('spriteLoad');
      }
    }
  }

  spriteLoad();

  $(window).on('resize scroll', function(){
    spriteLoad();
  });  
}




$('.js-lazy').unveil(400);

}

export default lazyImg;