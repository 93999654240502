module.exports={
  "type": "object",
  "required": [
    "size",
    "mimeType"
  ],
  "properties": {
    "size": {
      "type": "integer"
    },
    "compression": {
      "type": "integer"
    },
    "mimeType": {
      "type": "string"
    },
    "text": {
      "type": "string"
    },
    "encoding": {
      "type": "string"
    },
    "comment": {
      "type": "string"
    }
  }
}
