// import $ from 'jquery';
import '~/js/libs/magnific-popup';
// import 'waypoints';

const modal = () => {
    // let scrollTop = 0;

    $('.js-magnific-popup').magnificPopup({
        closeMarkup: '<button title="%title%" type="button" class="mfp-close"></button>',
        fixedContentPos: true,
        callbacks: {
            beforeOpen: function beforeOpenFunction() {
                window.Waypoint.disableAll();
                $('body').toggleClass('has-modal');
                $(document).trigger('Modal.Fixed', true);
            },
            afterClose: function closeFunction() {
                window.Waypoint.enableAll();
                $('body').toggleClass('has-modal');
                $(document).trigger('Modal.Fixed', false);
            }
        }
    });

    $('.js-magnific-video').magnificPopup({
        closeMarkup: '<span class="mfp-close"></span>',
        type: 'iframe',
        mainClass: 'video-popup',
        fixedContentPos: true
    });

   /* $('.js-popup-youtube').magnificPopup({
        closeMarkup: '<button title="%title%" type="button" class="mfp-close close-video-dining close-video-button"></button>',
        type: 'iframe',
        mainClass: 'dining-video-mark',
        fixedContentPos: true,
        iframe: {
            patterns: {
                youtube: {
                    index: 'youtube.com/',
                    id: 'v=',
                    src: '//www.youtube.com/embed/Q7CLkueklFU?rel=0&amp;autoplay=1&amp;showinfo=0'
                }
            }
        }
    });*/

    // TODO: This should not be here
    /* if(/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        $(document).on('Modal.Fixed', (e, data) => {
            if (data) {
                scrollTop = $(window).scrollTop();
                $('body').css('overflow', 'hidden');
                $('body').css('position', 'fixed');
                $('body').css('width', '100%');
                $('body').css('top', scrollTop * (-1) + 'px');
            } else {
                $('body').css('overflow', 'auto');
                $('body').css('top', '0');
                $('body').removeAttr('style');
                $(window).scrollTop(scrollTop);
            }
        });
    } */
};

export default modal;
